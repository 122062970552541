import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

// import { css } from '@emotion/react'
import BookCard from "../components/bookCard"
import MediaCard from "../components/mediaCard"
import TwoColumns from "../components/layouts/twoColumns"
import ThreeColumns from "../components/layouts/threeColumns"
import AddContent from "../components/addContent"
// import FourColumns from "../components/layouts/fourColumns"

const InspirationsPage = (props) => {
  const books = props.data.books.edges.map( edge => edge.node )
  const movies = props.data.movies.edges.map( edge => edge.node )
  const podcasts = props.data.podcasts.edges.map( edge => edge.node )
  // console.log(people)

  return (
    <Layout>
      <Seo title="Home" />

      <Container>
        <div style={{marginTop: "70px"}}>
          <AddContent 
            title={`${books.length} bonnes lectures`}
            link={"https://airtable.com/shrFGkDBB3CXFFOZf"}
            object="un livre"
          />

          <TwoColumns>
              {books.map( book => (
                <BookCard 
                  key={book.id}
                  name={book.data.Name}
                  year={book.data.Year}
                  description={book.data.Description}
                  tags={book.data.Tags}
                  show_desc
                  image={book.data.Cover && book.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                  authors={book.data.People && book.data.People.map( person => person.data.Name )}
                  authors_img={book.data.People && book.data.People.map( person => person.data.Image && person.data.Image.localFiles[0].childImageSharp.gatsbyImageData ).filter( url => url != null )}
                />
              ))}
          </TwoColumns>
        </div>

        <div style={{marginTop: "70px"}}>
          <AddContent 
            title={`${movies.length} films, séries, contenus vidéo`}
            link={"https://airtable.com/shr3ms5PXUBZheuXO"}
            object="une vidéo"
          />

          <ThreeColumns>
            {movies.map( movie => (
              <BookCard 
                key={movie.id}
                name={movie.data.Name}
                year={movie.data.Year}
                description={movie.data.Description}
                tags={movie.data.Tags}
                video={movie.data.Video}
                image={movie.data.Cover && movie.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                authors={movie.data.People && movie.data.People.map( person => person.data.Name )}
                authors_img={movie.data.People && movie.data.People.map( person => person.data.Image && person.data.Image.localFiles[0].childImageSharp.gatsbyImageData ).filter( url => url != null )}
              />
            ))}
          </ThreeColumns>
        </div>

        <div style={{margin: "70px 0"}} id="podcasts">
          <AddContent 
            title={`${podcasts.length} émissions Podcast`}
            link={"https://airtable.com/shrOkx4vsRPg9f5eP"}
            object="un podcast"
          />

          <ThreeColumns>
            {podcasts.map( podcast => (
              <MediaCard 
                key={podcast.id}
                name={podcast.data.Name}
                image={podcast.data.Image && podcast.data.Image.localFiles[0].childImageSharp.gatsbyImageData}
                link={podcast.data.Link}
                type={`Podcast`}
                person={podcast.data.People && podcast.data.People[0].data.Name}
                person_img={podcast.data.People && podcast.data.People[0].data.Image && podcast.data.People[0].data.Image.localFiles[0].childImageSharp.gatsbyImageData}
              />
            ))}
          </ThreeColumns>
        </div>
      </Container>
    </Layout>
  )
}

export default InspirationsPage

export const inspirationsQuery = graphql`
  query InspirationsQuery {
    books: allAirtable(
      filter: {table: {eq: "Books"}, data: {Status: {eq: "Published"} } },
      sort: {fields: data___Year, order: DESC}
    ) {
      edges {
        node {
          ...BookCardFragment
        }
      }
    }
    movies: allAirtable(
      filter: {table: {eq: "Movies"}, data: {Status: {eq: "Published"}}},
      sort: {fields: data___Year, order: DESC}
    ) {
      edges {
        node {
          ...MovieCardFragment
        }
      }
    }
    podcasts: allAirtable(filter: {table: {eq: "PodcastShows"}, data: {Status: {eq: "Published"}}}) {
      edges {
        node {
          id
          data {
            Name
            Image {
              id
              localFiles {
                  childImageSharp {
                      gatsbyImageData(
                          width: 200
                          quality: 100
                      )
                  }
              }
            }
            Link
            People {
              data {
                Name
              }
            }
          }
        }
      }
      totalCount
    }
  }
`